/*Склонение от значения*/
function plural(number, one, two, five) {
	let n = Math.abs(number);
	n %= 100;
	if (n >= 5 && n <= 20) {
		return five;
	}
	n %= 10;
	if (n === 1) {
		return one;
	}
	if (n >= 2 && n <= 4) {
		return two;
	}
	return five;
}

let calc = '.js-calculator',
	calcLam = '.js-calculator-lam',
	calcShop = '.js-calculator-shop',
	calcLength = '.js-calculator-length',
	calcWidth = '.js-calculator-width',
	calcSquere = '.js-calculator-square',
	calcMod = '.js-calculator-mod',
	calcResultSquere = '.js-calculator-result-square',
	calcResultCount = '.js-calculator-result-count',
	calcShoptCount = '.js-calculator-count',
	calcResultHide = '.js-calculator-hide',
	radioToggle = '.js-radio-toggle',
	shopCounter = '.js-shop-count',
	prvEl, prvChecked, interval, $count;

if ($(radioToggle).length) {
	$('body').on('click', radioToggle, function () {
		if (prvEl != this) {
			prvChecked = false;
			prvEl = this;
		}
		this.checked = !prvChecked;

		prvChecked = !prvChecked;
		if (prvChecked == false) {
			$('.js-calculator').change();
		}
	});
}

if ($(calc).length) {

	let squere = Number($('.js-shop-count').data('mincount'));
	$(calc).on('keyup change', function () {
		let userSquere = $(calcSquere).val(),
			mod = $(calcMod).find('input');

		if (mod.is(':checked')) {
			let modPercent = userSquere * ($(calcMod).find('input:checked').val() / 100);
			userSquere = Number(userSquere) + Number(modPercent);
		}

		if (Number(userSquere) > 0) {
			$(calcResultHide).show()
		} else {
			$(calcResultHide).hide()
		}


		if ($(calcResultCount).length) {
			$(calcResultCount).html(Math.ceil(userSquere / squere) + ' ' + plural(parseFloat(Math.ceil(userSquere / squere)), 'полная доска', 'полных доски', 'полных досок'));
		}

		$(calcResultSquere).html(parseFloat($(calcResultCount).html().split(' ')[0] * squere).toFixed(2));

	})

}

if ($(calcLam).length) {
	$(calcLam).on('keyup change', function () {
		let userLength = $(calcLength).val(),
			modVal = $(calcMod).find('input:checked').val(),
			result = userLength * modVal;
		if (Number(result) > 0) {
			$(calcResultHide).show()
		} else {
			$(calcResultHide).hide()
		}
		$(calcResultSquere).html(result)

	})
}

if ($(calcShop).length) {
	$(calcShop).on('change', function () {
		let userCount = Number($('.js-shop-count').val()),
			minCount = Number($('.js-shop-count').data('mincount')),
			price = Number($('.js-shop-price-num').html()),
			userRes = Math.ceil(userCount / minCount) * minCount,
			result = userRes * price;

		$('.js-shop-count').val(parseFloat(userRes).toFixed(2));
		$('.js-calculator-price').html(parseFloat(result).toFixed(2));
		$(calcShoptCount).html(parseFloat(Math.ceil(userCount / minCount)) + ' ' + plural(parseFloat(Math.ceil(userCount / minCount)), 'доска', 'доски', 'досок'));
	})
}

/*Изменение количества*/
function changeCountItem(context, clear) {
	if (clear != true) {
		interval = setInterval(function () {

			let $parent = context.parents(calcShop),
				$count = $parent.find('.js-shop-count'),
				mincount = Number($count.data('mincount')),
				price = Number($('.js-shop-price-num').html()),
				countVal = Number($count.val()),
				result;

			if (context.data('symbol') == 'decrement') {
				if ($count.val() <= 0) {
					return false;
				}
				result = countVal - mincount;

			} else {
				result = countVal + mincount;
			}

			$count.val(parseFloat(result).toFixed(2));
			$('.js-calculator-price').html(parseFloat(result * price).toFixed(2));
			$(calcShoptCount).html(parseFloat(Math.ceil($count.val() / mincount)) + ' ' + plural(parseFloat(Math.ceil($count.val() / mincount)), 'доска', 'доски', 'досок'));

		}, 50);
	} else {
		clearInterval(interval);
	}
}

$('body').on('mousedown touchstart', '.js-calculator-counter', function () {
	changeCountItem($(this), false)
});
$('body').on('mouseup touchend mouseout', '.js-calculator-counter', function () {
	changeCountItem($(this), true)

});