let asideMenu = '.js-aside-menu',
	 asideMenuList = '.js-aside-menu-list',
	 asideMenuItem = '.js-aside-menu-item',
	asideMenuArrow='.js-aside-menu-arrow',
	asideSubmenu = '.js-aside-menu-sublist';

if(asideMenu.length) {
	$('body').on('click', asideMenu, function () {
		$(this).next(asideMenuList).slideToggle();
	});
	$('body').on('click', asideMenuArrow, function (e) {
		e.preventDefault();
		$(this).toggleClass('active').closest(asideMenuItem).find(asideSubmenu).slideToggle()
	})
}
