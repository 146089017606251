import mask from 'inputmask/dist/jquery.inputmask.bundle';
/*маска на телефон*/
function maskInput(body){
	$(body).find('.js-phone-mask').inputmask({"mask": "+375 (99) 999-99-99"});
};
let rating = '.js-star-rating',
	ratingItem = '.js-star-rating-item',
	ratingVal = '.js-star-rating-val',
	ajaxFormReview = '.js-ajax-reviews',
	$formReview = $(ajaxFormReview).find('form');

if($(rating).length) {
	$(ratingItem).click(function(){
		$(this).parents(rating).find(ratingVal).val($(this).data('rating'));
		$(this).parents(rating).attr('data-stars', $(this).data('rating'));
	});
}

if(ajaxFormReview.length) {
	$(ajaxFormReview).on('submit', $formReview, function (e) {

		let $this = $(this).find('form');

		$.ajax({
			url: '/ajax-review.php',
			type: 'post',
			data: $this.serialize(),
			success: function (data) { // при успехе

				let parseData = JSON.parse(data);

				if (parseData.success == 'Y' && parseData.res != '') {
					$this.parents(ajaxFormReview).html(parseData.res);
				} else {
					$this.find('.forms__errors').html('Форма заполнена неверно');
					setTimeout(function () {
						$this.find('.forms__error').html('')
					},5000)
				}
				maskInput(ajaxFormReview);
				if($(rating).length) {
					$(ratingItem).click(function(){
						$(this).parents(rating).find(ratingVal).val($(this).data('rating'));
						$(this).parents(rating).attr('data-stars', $(this).data('rating'));
					});
				}
			},
			error: function (data) { // при неудаче
				alert('Что-то пошло не так. Пожалуйста, попробуйте позже.')
			}
		});
		e.preventDefault();
	});
}

