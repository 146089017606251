let popup = '.js-popup',
	popupBtn ='.js-popup-form',
	fancyCloseBtn ='.js-fancy-close',
	popupFormTitle='.js-popup-form-title',
	popupFormImg='.js-popup-form-img',
	popupFormPrice='.js-popup-form-price',
	popupFormVolume='.js-popup-form-volume',
	popupFormFullPrice='.js-popup-form-full-price',
	formHiddenPrice = '.js-input-form-price',
	formHiddenTitle = '.js-input-form-title',
	formHiddenLink = '.js-input-form-link',
	formHiddenCount = '.js-input-form-count',
	formHiddenFullPrice = '.js-input-form-full-price',
	shopItem, shopTitle, shopImg, shopPrice, shopCount,shopCountVal;

if (popup.length) {
	$('body').on('click',popupBtn,function (e) {
		e.preventDefault();
		let $target = $(e.target).hasClass(popupBtn)?$(e.target):$(e.target).closest(popupBtn);

		shopItem = $target.closest('.js-shop-item');
		shopTitle = shopItem.find('.js-shop-title');
		shopImg = shopItem.find('.js-shop-img').attr('src');
		shopPrice = shopItem.find('.js-shop-price-num').text();
		shopFullPrice = shopItem.find('.js-calculator-price').html();
		shopUnits = shopItem.find('.js-shop-price').data('units') == 'm2'?' <span>м<sup>2</sup></span>':shopItem.find('.js-shop-price').data('units');
		shopCount = shopItem.find('.js-shop-count');
		shopCountVal = shopCount.val()>0?shopCount.val():shopCount.data('mincount').toFixed(2);

		let title = shopTitle.text(),
				link = shopTitle.attr('href')?shopTitle.attr('href'):window.location.href,
				fullPrice = shopFullPrice>0?shopFullPrice:(shopCountVal*shopPrice).toFixed(2);


		$(popupFormTitle).html(title).attr('href',link);
		$(popupFormPrice).html(shopPrice);
		$(popupFormImg).attr('src',shopImg);
		$(popupFormVolume).html(shopCountVal + shopUnits);
		$(popupFormFullPrice).html(fullPrice);
		$(formHiddenPrice).val(shopPrice);
		$(formHiddenTitle).val(title);
		$(formHiddenLink).val(link);
		$(formHiddenCount).val(shopCountVal);
		$(formHiddenFullPrice).val(fullPrice);

	});

	$(popupBtn).fancybox({
		infobar:false,
		centerOnScroll : true,
		touch:false
	});
	$('body').on('click',fancyCloseBtn, function (e) {
		let $target = $(e.target).hasClass(fancyCloseBtn)?$(e.target):$(e.target).closest(fancyCloseBtn);
		$.fancybox.close(true);
	})
}
