let $uploadFile = '.js-file-upload',
	$uploadFileBtn = 'js-file-upload-btn',
	$uploadPreloader = $('.js-file-loader'),
	uploadFileText = '.js-file-upload-text';

	if($uploadFile.length) {
		$('body').on('change','.'+$uploadFileBtn, function(e) {
			let $target = $(e.target).hasClass($uploadFileBtn)?$(e.target):$(e.target).closest($uploadFileBtn);
			if($target.length) {

				let filename = $target.val().replace(/.*\\/, ""),
					$thisText = $target.closest($uploadFile).find(uploadFileText);
				console.log(filename, $thisText);
				$thisText.val(filename).show();
			}
		});

	}
