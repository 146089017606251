import sumoselect from 'sumoselect/jquery.sumoselect';

/*прокрутка*/
function scroll(element, offset) {
	$('body, html').animate({scrollTop: offset}, 500, function (e) {
		element.removeClass('active');

	});
}

/*удаление из массива*/
Array.prototype.remove = function (value) {
	var idx = this.indexOf(value);
	if (idx != -1) {
		// Второй параметр - число элементов, которые необходимо удалить
		return this.splice(idx, 1);
	}
	return false;
}

/*кастомизированный селект*/
function sumo(body) {
	if ($('.js-select').length) {
		$(body).find('.js-select').SumoSelect();
	}
}

function plural(number, one, two, five) {
	let n = Math.abs(number);
	n %= 100;
	if (n >= 5 && n <= 20) {
		return five;
	}
	n %= 10;
	if (n === 1) {
		return one;
	}
	if (n >= 2 && n <= 4) {
		return two;
	}
	return five;
}

function maskInput(body) {
	$(body).find('.js-phone-mask').inputmask({"mask": "+375 (99) 999-99-99"});
}

let cart = '.js-cart',
	$body = $('body'),
	cartDeliveryItem = '.js-cart-delivery-item',
	cartDeliveryPrice = '.js-cart-delivery-price',
	cartDeliveryRadio = '.js-cart-delivery-radio',
	cartSelectWr = '.js-cart-select-wr',
	cartMessage = '.js-cart-message',
	$cartTotalPrice = $('.js-cart-total-price'),
	$cartChoose = $('.js-cart-choose'),
	storageData = JSON.parse(localStorage.getItem("evoShop_items")),
	$count,
	$grandTotalResult = $('.js-cart-grand-total-with-delivery'),
	$grandTotalWithoutDelivery = $('.js-cart-grand-total'),
	cartItems = $('.js-cart-item'),
	cartRemove = '.js-cart-remove',
	header = '.js-fixed',
	cartSelect = '.js-cart-select',
	cartBtn = '.js-cart-ajax-btn',
	typeOfPay = '.js-cart-pay-radio',
	onlinePay = '.js-cart-online-pay',
	cartChooseMessage = '.js-cart-choose-message',
	$cartDeliveryCost = $('.js-cart-delivery-cost'),
	$cartFullPrice = $('.js-cart-full-price'),
	interval, checkedIds = [];


function attrsForForm($form) {
	let action = $form.data('action'),
		target = $form.data('target');
	$form.attr({action: action, target: target});
}

function sendOnline(e,form) {
	let top = $(form).find('.js-cart-pay-radio:checked'),
		op = $(form).find(onlinePay);
		console.log(top,op,op.attr('disabled'));
	if (top.val() === 'erip') {
		op.prop('disabled', false);
		$(form).find("[name='EP_PayType']").prop('disabled', false);
		attrsForForm(form);
		return true;
	} else if (top.val() === 'card') {
		op.prop('disabled', false);
		attrsForForm(form);
		return true;
	} else {
		op.prop('disabled', true)
		e.preventDefault();
	}
}

/*отправка заказа*/
if (cartBtn.length) {
	$(document).on('click', cartBtn, function (event) {

		let $thisFormParent = $(this).parents(cart),
			$form = $thisFormParent.find('form'),
			$data = [];
		if ($('.js-cart-choose-ids').val() == '') {
			let scrollToOffset = $('#scroll_to').offset().top - $(header).height();
			scroll($('body'), scrollToOffset);
			$(cartMessage).show();
			return false;
		}
		$(cartMessage).hide();
		for (let key in storageData) {
			if ($('input[name="' + key + '"]').prop('checked')) {
				$data.push(storageData[key]);
			}
		}

		$(".js-cart-order").val(JSON.stringify($data).replace("}}", "} }"));
		$.ajax({
			url: '/ajax-cart-order.php',
			type: 'post',
			data: $form.serialize(),
			success: function (data) { // при успехе

				let parseData = JSON.parse(data);

				if (parseData.status === true) {
					$form.parents('.js-cart-form-container').html(parseData.res);
					let idsArray = parseData.ids.split(',');

					$.each(idsArray, function (i, id) {
						delete storageData[id];
						$('#' + id).remove()
					});
					localStorage.setItem("evoShop_items", JSON.stringify(storageData));
					$('<div class="small-cart__bg js-cart-plank" style="display: none;">' +
						'<div class="small-cart__informer  small-cart__informer--fixed">' +
						'<h2 style="text-indent: 0;">' +
						'Ваша заявка принята в обработку!' +
						'</h2>' +
						'<h4>' +
						'Мы свяжемся с Вами в ближайшее время.' +
						'</h4>' +
						'<div class="row center-sm">' +
						'<div class="col-md-5 col-sm-7 small-cart__informer-btn"><a href="javascript:void(0)" class="btn btn--full js-cart-reload">Спасибо</a></div>' +
						'</div>' +
						'</div>').appendTo($('body'));
					$('.js-cart-plank').fadeIn(400);


				} else {
					$form.parents('.js-cart-form-container').html(parseData.res);
					let scrollToOffset = $('.js-cart-form-container').offset().top - $(header).height();
					scroll($('body'), scrollToOffset)
				}

				sumo($thisFormParent);
				maskInput($thisFormParent);
			},
			error: function (data) { // при неудаче
				alert('Что-то пошло не так. Пожалуйста, попробуйте позже.')
			}
		});
		 sendOnline(event,$form);

	});
}

function reCounter($parent) {
	let $count = $parent.find('.js-cart-mincount'),
		mincount = parseFloat($count.data('mincount')).toFixed(2),
		countVal = Number($count.val());

	if ($parent.find('.js-calculator-count').length) {
		$parent.find('.js-calculator-count').html(Math.ceil(countVal / mincount) + plural(Math.ceil(countVal / mincount), ' доска', ' доски', ' досок'));
	}
}

/*Изменение количества в полной корзине*/
function changeCountItem(context, clear) {
	if (clear !== true) {
		interval = setInterval(function () {

			let $parent = context.parents('.js-cart-item'),
				$id = $parent.attr('id'),
				$price = storageData[$id].price,
				$totalPrice = $parent.find('.js-total-price');

			$count = $parent.find('.js-cart-mincount');
			let mincount = Number($count.data('mincount')),
				countVal = Number($count.val()),
				result;

			if (context.data('symbol') == 'decrement') {
				if (countVal <= mincount) {
					return false;
				}
				result = countVal - mincount;
			} else {
				result = countVal + mincount;
			}

			$count.val(parseFloat(result).toFixed(2));

			$totalPrice.html(parseFloat(result * $price).toFixed(2));
			storageData[$id].total = parseFloat(result * $price).toFixed(2);

			storageData[$id].count = parseFloat(result).toFixed(2);

			changeGrandTotalPrice(null, null, true);

			deliveryPrice($('.js-cart-delivery-minsk'));
			let $selectOption = $('#delivery').find('option:selected');
			if ($selectOption.data('info')) {
				deliveryPrice($selectOption);
			}

		}, 50);
	} else {
		clearInterval(interval);
	}
}

/*устанавливаем значения цен*/
function setPrices(itemPrice, price, fullPrice) {
	if (itemPrice) {
		itemPrice.html(price);
	}

	$cartFullPrice.val(fullPrice);
	$grandTotalResult.html(fullPrice);
	$cartDeliveryCost.val(price);

}

/*Изменение цены доставки*/
function deliveryPrice(element) {
	let thisCartItemPrice = element.closest(cartDeliveryItem).find(cartDeliveryPrice);

	let data = element.get(0).hasAttribute("data-info") ? element.data('info') : element.closest($(cartSelectWr)).find('option:selected').data('info'),
		priceWithoutDelivery = $cartTotalPrice.attr('data-price-without-delivery'),
		$price = parseFloat($cartTotalPrice.attr('data-price-without-delivery'));
	if(data) {
		data.forEach(function (item, i, arr) {

			if (parseFloat(priceWithoutDelivery) >= parseFloat(item.sumFrom) && parseFloat(priceWithoutDelivery) <= parseFloat(item.sumTo) && parseFloat(item.sumTo) !== 0 || parseFloat(priceWithoutDelivery) >= parseFloat(item.sumFrom) && parseFloat(item.sumTo) === 0) {

				setPrices($price > 0 ? thisCartItemPrice : null, parseFloat(item.value), $price > 0 ? ($price + parseFloat(item.value)).toFixed(2) : '0')
			}

		})
	} else {
		setPrices($price > 0 ? thisCartItemPrice : null, 0, $price)
	}

}

/*Изменение полной стоимости*/
function changeGrandTotalPrice(element, del, save) {
	if (del === true) {
		let $parent = element.parents('.js-cart-item'),
			$id = $parent.attr('id');
		delete storageData[$id];
		$parent.remove();
	}

	let grandTotal = 0;
	for (let key in storageData) {
		if ($('input[name="' + key + '"]').prop('checked')) {
			grandTotal += parseFloat(storageData[key].total);
		}
	}
	$cartTotalPrice.attr('data-price-without-delivery', grandTotal);
	$grandTotalWithoutDelivery.html(parseFloat(grandTotal).toFixed(2));


	if (save === true) {
		localStorage.setItem("evoShop_items", JSON.stringify(storageData));
	}

}

/*Выбор способа оплаты*/
function chooseTypeOfPay() {

}

/*Выбор способа доставки*/
$body.on('change', cartDeliveryRadio, function () {

	let selectWr = $(this).closest($(cartSelectWr));

	$(cartSelectWr).find(cartSelect).prop('disabled', true)[0].sumo.reload();
	if (selectWr.length) {
		selectWr.find(cartSelect).prop('disabled', false)[0].sumo.reload();
	}
	if ($(this).data('info')) {
		deliveryPrice($('body').find('.js-cart-delivery-minsk'))
	} else {
		$(cartSelect).find('option').eq(1).prop('selected', true);
		$(cartSelect)[0].sumo.reload();
		deliveryPrice($(cartSelect).find('option').eq(1))
	}

});

$(document).on('change', '#delivery', function () {

	let $selectOption = $('#delivery').find('option:selected');
	deliveryPrice($selectOption);

});

$body.on('mousedown touchstart', '.js-cart-count', function (e) {
	let parent = $(e.target).closest('.js-cart-item');
	changeCountItem($(this), false);
	reCounter(parent)
});
$body.on('mouseup touchend mouseout', '.js-cart-count', function (e) {
	let parent = $(e.target).closest('.js-cart-item');
	changeCountItem($(this), true);
	reCounter(parent)

});

/*Удаление из корзины*/
$body.on('click', cartRemove, function () {

	changeGrandTotalPrice($(this), true, true);
	deliveryPrice($('.js-cart-delivery-minsk'));
	if (Object.keys(storageData).length == 0) {
		$(cart).hide();
		$('.js-cart-empty').show();

	} else {
		$(cart).show();
		$('.js-cart-empty').hide();


	}
});


/*Рендер полной корзины*/
function makeCart() {

	for (let item in storageData) {
		let counter = '',
			units = storageData[item].units === 'm2' ? 'м<sup>2</sup>' : storageData[item].units;
		if (storageData[item].calc != 'other') {
			counter = '<div class="full-cart__count"><i class="js-calculator-count"></i></div>'
		} else {
			counter = ''
		}
		$('.js-cart-container').append(
			'<div class="full-cart__item js-cart-item" id="' + storageData[item].id + '">' +
			'<div class="row top-xs middle-lg">' +
			'<div class="col-lg-4 col-sm-10">' +
			'<div class="full-cart__wrapper">' +
			'<label class="checkbox full-cart__checkbox">' +
			'<input type="checkbox" name="' + storageData[item].id + '" class="js-cart-choose" value="1">' +
			'<span class="checkbox__block">' +
			'<svg class="svg-icon" width="60px" height="60px">' +
			'<use xlink:href="/assets/templates/laminado/svg-symbols.svg#tick"></use>' +
			'</svg>' +
			'</span>' +
			'</label>' +
			'<a class="full-cart__link" href="' + storageData[item].link + '" ><img class="full-cart__img" src="' + storageData[item].image + '" alt="' + storageData[item].name + '"/></a>' +
			'<div class="full-cart__block">' +
			'<a href="' + storageData[item].link + '" class="full-cart__description item_name">' + storageData[item].name + '' +
			'</a><a class="full-cart__remove js-cart-remove" href="javascript:void(0)">удалить из корзины</a>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'<div class="col-lg-1 col-sm-3">' +
			'<div class="full-cart__price"><span class="js-cart-price">' + storageData[item].price + ' </span>руб' +
			'</div>' +
			'</div>' +
			'<div class="col-lg-4 col-sm-4">' +
			'<div class="row middle-xs center-xs">' +
			'<div class="full-cart__count">' +
			'<div class="full-cart__count-minus js-cart-count" data-symbol="decrement">-' +
			'</div>' +
			'<input class="full-cart__input js-cart-mincount" disabled type="text" data-mincount="' + storageData[item].mincount + '" value="' + storageData[item].count + '"/>' +
			'<div class="full-cart__count-plus js-cart-count" data-symbol="increment">+</div>' +
			'</div>' +
			'<div>&nbsp;' + units + '</div>' +
			'</div>' +
			counter +
			'</div>' +
			'<div class="col-lg-1 col-sm-3">' +
			'<div class="full-cart__total-price"><span class="js-total-price">' + storageData[item].total + ' </span> руб' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>'
		);
		reCounter($('#' + item))
	}

	if (storageData == null) {
		$(cart).hide();
		$('.js-cart-empty').show();

	} else {
		$(cart).show();
		$('.js-cart-empty').hide();
	}
	/*Полная стоимость в полной корзине*/
	/*Полная стоимость в полной корзине*/

	cartItems.each(function (i, e) {

		let $id = $(e).attr('id'),
			$price = storageData[$id].price,
			$totalPrice = $(e).find('.js-total-price'),
			$count = storageData[$id].count;

		storageData[$id].total = parseFloat($count * $price).toFixed(2);
		$totalPrice.html(parseFloat($count * $price).toFixed(2) + 'руб');
	});
	/*Считаем итоговую сумму при загрузке*/
	changeGrandTotalPrice(null, null, null);
	deliveryPrice($('.js-cart-delivery-minsk'));
}

if ($(cart).length) {

	makeCart();

	$('body').on('change', $cartChoose, function (e) {
		let parent = $(e.target).closest('.js-cart-item'),
			id = parent.attr('id');
		if (parent.find('.js-cart-choose:checked').length) {
			checkedIds.push(id);

		} else {
			checkedIds.remove(id);

		}

		$('.js-cart-choose-ids').val(checkedIds.join(','));
		if ($('.js-cart-choose-ids').val() != '') {
			$(cartChooseMessage).hide();
		} else {
			$(cartChooseMessage).show();

		}

		changeGrandTotalPrice(null, null, null);
		deliveryPrice($('.js-cart-delivery-radio:checked'));

	});
	$(document).on("click", function (e) {

		if ($(e.target).hasClass('js-cart-plank') || $(e.target).hasClass('js-cart-reload')) {
			$('.js-cart-plank').fadeOut(400).remove();
			window.location.reload()
		}
	});


}






