
/*Добавление товара в корзину*/
let quantity = 0,
	parentObj = {},
	totalPrice;
if(localStorage.getItem("evoShop_items") == null) {

	var data = {};
	localStorage.setItem('evoShop_items', JSON.stringify(data));

} else {

	var data = JSON.parse(localStorage.getItem("evoShop_items"));


}
parentObj = data;

$(document).ready(function () {

	/*Маленькая корзина*/
	for (let item in data) {
		$('.js-evoShop_cart-count').html(Object.keys(data).length);

	}

	if (Object.keys(data).length == 0) {
		$('.js-evoShop_cart-link').removeClass('active').attr('href', 'javascript:void(0)')
	} else {
		$('.js-evoShop_cart-link').addClass('active').attr('href', '/cart')
	}
});

$(document).on("click", ".js-shop-add", function (ev) {
	let $this = $(ev.target).closest('.js-shop-item'),
			parentLink = $(ev.target).attr("data-link")?$(this).data('link'):'javascript:void(0)',
		id = $this.data('id');
	if ($this.find('.js-shop-count').val() <= 0) {

		$this.find('.js-shop-count').val($this.find('.js-shop-count').data('mincount').toFixed(2))
	}
	ev.preventDefault();
	if($this.find('.js-calculator-price').length){
		totalPrice = Number($this.find('.js-calculator-price').html())
	} else {
		totalPrice = Number($this.find('.js-shop-price-num').html()) * Number($this.find('.js-shop-count').val());
	}
	let evoItem = {
		id: id,
		name: $this.find('.js-shop-title').html(),
		image: $this.find('.js-shop-img').first().attr('src'),
		price: $this.find('.js-shop-price-num').html(),
		count: $this.find('.js-shop-count').val(),
		mincount: $this.find('.js-shop-count').data('mincount'),
		total: parseFloat(totalPrice.toFixed(2)),
		link: $this.find('.js-shop-link').attr('href'),
		units: $this.find('.js-shop-price').data('units'),
		calc: $this.get(0).hasAttribute('data-calc')?$this.data('calc'):'other'
	};

	parentObj[id] = evoItem;

	if (!!window.localStorage) {
		try {
			localStorage.setItem("evoShop_items", JSON.stringify(parentObj));
			$('.js-evoShop_cart-count').html(Object.keys(data).length);
		}
		catch (e) {
			evoShop.error("Error storing data: " + e);
		}
	}

	if (Object.keys(data).length == 0) {
		$('.js-evoShop_cart-link').removeClass('active').attr('href', 'javascript:void(0)')
	} else {
		$('.js-evoShop_cart-link').addClass('active').attr('href', '/cart')
	}


	$('<div class="small-cart__bg js-evoShop_plank" style="display: none;">' +
		'<div class="small-cart__informer  small-cart__informer--fixed">' +
		'<h4>'+
		'Товар добавлен в корзину' +
		'</h4>' +
		'<div class="row between-lg">' +
		'<div class="col-lg-5 col-sm-10 small-cart__informer-btn"><a href="/cart" class="btn btn--full">Перейти в корзину</a></div>' +
		'<div class="col-lg-5 col-sm-10"><a href="'+
		parentLink
		+'" class="btn btn--full btn--grey js-evoShop_close">Продолжить покупки</a></div>' +
		'</div>' +
		'</div>' +
		'</div>').appendTo($('body'));
	$('.js-evoShop_plank').fadeIn(400);
});
$(document).on("click", function (e) {

	if ($(e.target).hasClass('js-evoShop_plank') || $(e.target).hasClass('js-evoShop_close')) {
		$('.js-evoShop_plank').fadeOut(400).remove();
	}
});
