let search = '.js-search',
	searchWrap = '.js-search-wrap',
	userInput = '.js-search-input',
	searchResult = $('.js-search-result'),
	btnMore = '.js-search-more',
	count = $(search).data('count');
let fnDelay = (function () {
	let timer = 0;
	return function (callback, ms) {
		clearTimeout(timer);
		timer = setTimeout(callback, ms);
	};
})();
$('body').click(function (event) {
	if ($(event.target).closest(search).length) return;
	$(searchResult).hide();

});
$('body').on('keyup', userInput, function () {
	let $this = $(this),
		searchResult = $this.parents(searchWrap).find('.js-search-result');

	let userInputVal = $this.val();

	if (userInputVal.length >= 3) {
		fnDelay(function () {
			$.ajax({
				url: '/ajax-search.php',
				type: 'get',
				data: {
					search: userInputVal
				},
				success: function (data) { // при успехе

					let parseData = JSON.parse(data);
					if (parseData.success == 'Y') {
						searchResult.html(parseData.res);
						$(searchResult).show();
						if (parseData.count > count) {
							$(searchResult).append('<div class="search__more"><a class="search__btn btn js-search-more" href="/search/?search=' + userInputVal + '">Все результаты</a></div>')

						}

					} else {
						alert('Что-то пошло не так. Пожалуйста, попробуйте позже.')
					}

				},
				error: function (data) { // при неудаче
					alert('Что-то пошло не так. Пожалуйста, попробуйте позже.')
				}
			})
		}, 500)
	}

});