let question = '.js-question',
	questionPopup = '.js-question-popup';

if (question.length) {
	$('body').on('click', question, function () {
		$(question).removeClass('active');
		$(this).toggleClass('active');
	});
	$(document).on('click', function(e) {
		if (!$(e.target).closest(question).length) {
			$(question).removeClass('active');
		}
		e.stopPropagation();
	});
}