let fixed = '.js-fixed';

if($(fixed).length) {

		$(window).scroll(function () {
			if($(this).scrollTop() > 300) {
				$(fixed).addClass('active')
			} else {
				$(fixed).removeClass('active')
			}
		})


}
