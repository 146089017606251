let tabs ='.js-tabs',
		tabsItem = '.js-tabs-item',
		tabsBody = '.js-tabs-body';

if($(tabs).length) {
	$(tabs).find(tabsItem).first().addClass('active');
	$(tabs).find(tabsBody).first().addClass('active');

	$('body').on('click', tabsItem, function (e) {
		e.preventDefault();
		let link = $(this).find('a').attr('href');

		$(tabsItem).removeClass('active');
		$(this).addClass('active');

		$(tabsBody).removeClass('active');
		$(tabs).find('#'+link).addClass('active');
	});

}
