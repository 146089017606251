let scrollTopEl = '.js-scroll-top',
	scrollTo = '#scroll_to',
	header = '.js-fixed',
	shopItem = '.js-shop-item',
	hash = window.location.hash;

function addVisible(element) {
	element.addClass('active')
}

function removeVisible(element) {
	element.removeClass('active')
}

/*прокрутка*/
function scroll(element, offset) {
	$('body, html').animate({scrollTop: offset}, 500, function (e) {
		element.removeClass('active');

	});
}

$(window).on('load', function (e) {
	if(localStorage.getItem('pagePositionLam') === null) {
		localStorage.setItem('pagePositionLam', JSON.stringify({}));
	}

	if(localStorage.getItem('pagePositionLam').length && $(window).width() <= 960 && $('.js-catalog').length) {
		let pagePosition = JSON.parse(localStorage.getItem('pagePositionLam'));
		if(pagePosition.url === window.location.href) {
			let scrollToOffset = $('#'+pagePosition.id).offset().top - $(header).height();
			scroll($('body'), scrollToOffset)
		} else {
			localStorage.setItem('pagePositionLam', JSON.stringify({}))
		}
	}
});

$('body').on('click',shopItem, function (e) {
	let $target = $(e.target).hasClass(shopItem)?$(e.target):$(e.target).closest(shopItem),
		$id = $target.data('id');
	localStorage.setItem('pagePositionLam', JSON.stringify({element:$id, url: window.location.href}));

});

/*кнопка вверх*/
if ($(scrollTopEl).length) {

	$(window).on('load scroll', function () {
		if ($(window).scrollTop() > 0) {
			addVisible($(scrollTopEl))
		} else {
			removeVisible($(scrollTopEl))
		}
	});

	$('body').on('click', scrollTopEl, function () {
		scroll($(this), 0)

	})
}

/*плавная прокрутка до хэша*/
if (hash.length) {
	$(window).on('load', function () {
		let scrollToOffset = $(hash).offset().top - $(header).height();
		scroll($('body'), scrollToOffset)
	})
}


/*проурутка на моб. версии до нужной точки*/
if ($(scrollTo).length && !hash.length) {
	$(window).on('load', function () {
		let scrollToOffset = $(scrollTo).offset().top - $(header).height();
		if ($(window).width() <= 960 && !JSON.parse(localStorage.getItem('pagePositionLam')).length) {
			scroll($('body'), scrollToOffset)
		}
	})
}



