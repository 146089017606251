function maskInput(body) {
	$(body).find('.js-phone-mask').inputmask({"mask": "+375 (99) 999-99-99"});
}

let ajaxForm = '.js-ajax-form',
	formBtn = '.js-ajax-form-btn',
	formLoader = '.js-ajax-form-loader';


if (formBtn.length) {
	$(document).on('click', formBtn, function (event) {
		let $thisFormParent = $(this).parents(ajaxForm),
			$form = $thisFormParent.find('form'),
			data = new FormData($form[0]);
		if ($form.find('.js-ajax-file').length) {
			$.each($form.find('.js-ajax-file')[0].files, function (i, file) {
				data.append('tech[]', file);
			});

		}
		if ($form.find(formLoader).length) {
			$(formLoader).show();
		}


		$.ajax({
			url: '/ajax-form-gauger.php',
			type: 'post',
			data: data,
			cache: false,
			contentType: false,
			processData: false,
			success: function (data) { // при успехе

				let parseData = JSON.parse(data);

				if (parseData.status === true) {
					$form.parents(ajaxForm).html(parseData.res);
					if(parseData.popup === true) {
						$('<div class="small-cart__bg js-evoShop_plank" style="display: none;">' +
							'<div class="small-cart__informer  small-cart__informer--fixed">' +
							'<h2 style="text-indent: 0;">'+
							'Ваша заявка принята в обработку!' +
							'</h2>' +
							'<h4>'+
							'Мы свяжемся с Вами в ближайшее время.' +
							'</h4>' +
							'<div class="row center-sm">' +
							'<div class="col-md-5 col-sm-7 small-cart__informer-btn"><a href="javascript:void(0)" class="btn btn--full js-evoShop_close">Спасибо</a></div>' +
							'</div>' +
							'</div>').appendTo($('body'));
						$('.js-evoShop_plank').fadeIn(400);
					}


				} else {
					$form.parents(ajaxForm).html(parseData.res);
				}
				if ($form.find(formLoader).length) {
					$(formLoader).hide();
				}
				maskInput($thisFormParent);
			},
			error: function (data) { // при неудаче
				alert('Что-то пошло не так. Пожалуйста, попробуйте позже.')
			}
		});
		event.preventDefault();


	});
}