import fancybox from '@fancyapps/fancybox/dist/jquery.fancybox';
import slick from 'slick-carousel/slick/slick';
import zoomz from './plugins/zoomz.js';
// import base64 from 'js-base64';
import sumoselect from 'sumoselect/jquery.sumoselect';


/*маска на телефон*/
function maskInput(body) {
	$(body).find('.js-phone-mask').inputmask({"mask": "+375 (99) 999-99-99"});
}

/*кастомизированный селект*/
function sumo(body) {
	if ($('.js-select').length) {
		$(body).find('.js-select').SumoSelect();
	}
}

/*разрешать ввод только чисел и точек*/
$('.js-number-dot').keyup(function () {
	$('.js-number-dot').val($('.js-number-dot').val().replace(/[^\d\.]/g, ""));
	if ($('.js-number-dot').val().match(/\./g) && $('.js-number-dot').val().match(/\./g).length > 1) {
		$('.js-number-dot').val($('.js-number-dot').val().substr(0, $('.js-number-dot').val().lastIndexOf(".")))
	}
});

function flyContent() {
	let flyContent = '.js-fly-content',
		flyposition = '.js-fly-position',
		flystart = '.js-fly-start';
	if ($(flyContent).length) {
		if ($(window).width() < 960) {

			$(flyContent).appendTo(flyposition);

		} else {
			$(flyContent).appendTo(flystart);
		}
	}
}

$(document).ready(function () {
	flyContent();
});
$(window).on('resize', function () {
	flyContent();
});
/*попап для всплывающего видео*/
import video from 'components/video/video';
/*появление плавающей шапки после 300 пикселей*/
import header from 'components/header/header';
/*одинарная карусель вместе с увеличением картинки*/
import singleThumbCarousel from 'components/single-thumb-carousel/single-thumb-carousel';
/*карусель с большим количеством слайдов*/
import carousel from 'components/carousel/carousel';
/*табы*/
import tabs from 'components/tabs/tabs';
/*простые калькуляторы на ламинат и линолеум*/
import calc from 'components/calculator/calculator';
/*аякс подгрузка каталога*/
import catalog from 'components/catalog/catalog';
/*аякс поиск*/
import search from 'components/search/search';
/*звёзды рейтинга*/
import forms from 'components/forms/forms';
/*звёзды рейтинга*/
import rating from 'components/reviews/reviews';
/*заявка на товар*/
import popupForm from 'components/popup-form/popup-form';
/*значок со всплвающим текстом*/
import question from 'components/question/question';
/*главное мобильное меню*/
import mobileMenu from 'components/main-menu/main-menu';
/*каталог мобильное меню*/
import asideMenu from 'components/aside-menu/aside-menu';
/*стрелка вверх/вниз*/
import scrollTop from 'components/scroll-top/scroll-top';
/*текст для кастомизированого input file*/
import inputFile from 'components/input-file/input-file';


/*полная корзина*/
import fullCart from 'components/full-cart/full-cart';
/*малая корзина*/
import smallCart from 'components/small-cart/small-cart';


$(() => {
	maskInput('body');
	sumo('body');
});
