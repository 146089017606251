let carousel =$('.js-carousel'),
	carouselThree = $('.js-carousel-three');
if(carousel.length) {
	$(carousel).slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		useCSS: true,
		useTransform:true,
		prevArrow: '<button type="button" class="slick-prev"><svg class="svg-icon"><use xlink:href="/assets/templates/laminado/svg-symbols.svg#arrow-slider"></use></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg class="svg-icon"><use xlink:href="/assets/templates/laminado/svg-symbols.svg#arrow-slider"></use></svg></button>',
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}

		]
	});

}

if(carouselThree.length) {
	$(carouselThree).slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		useCSS: true,
		useTransform:true,
		prevArrow: '<button type="button" class="slick-prev"><svg class="svg-icon"><use xlink:href="/assets/templates/laminado/svg-symbols.svg#arrow-slider"></use></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg class="svg-icon"><use xlink:href="/assets/templates/laminado/svg-symbols.svg#arrow-slider"></use></svg></button>',
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}

		]
	});

}
