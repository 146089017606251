let singleCarousel ='.js-carousel-single',
	fancyPopupImage = '.js-fancy-image',
	zoom = '[data-zoomz]';
if($(singleCarousel).length) {
	$(singleCarousel).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		useCSS: true,
		useTransform:true,
		fade:true,
		asNavFor: '.js-carousel-thumbs'
	});
	$('.js-carousel-thumbs').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.js-carousel-single',
		dots: false,
		arrows:true,
		infinite:false,
		centerMode: false,
		focusOnSelect: true,
		prevArrow: '<button type="button" class="slick-prev"><svg class="svg-icon"><use xlink:href="/assets/templates/laminado/svg-symbols.svg#arrow"></use></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg class="svg-icon"><use xlink:href="/assets/templates/laminado/svg-symbols.svg#arrow"></use></svg></button>'
	});
}
if($(zoom).length) {
if($(window).width() > 960) {
	$(window).on('load',function(){
		$(zoom).zoomz();
	});
}


}

if($(fancyPopupImage).length) {
	$(fancyPopupImage).fancybox({
		openEffect: 'none',
		closeEffect: 'none'
	});
}