let catalog = '.js-catalog',
	catalogMoreBtn = '.js-catalog-more',
	params = '.js-catalog-params',
	catalogDisplay = $(params).data('display'),
	catalogCount = '.js-catalog-count',
	catalogAllCount = '.js-catalog-all-count',
	catalogParent = $(params).data('parent');


/*парсим урл и получаем ключ=значение*/
function parseUrlQuery() {
	let data = {};
	if (location.search != '') {
		var pair = (location.search.substr(1)).split('&');
		for (var i = 0; i < pair.length; i++) {
			var param = pair[i].split('=');
			data[param[0]] = param[1];
		}
	}
	return data;
}

if ($(catalog).length) {

	$('body').on('click', catalogMoreBtn, function () {

		let display,
			urlCount,
			catalogMore = $(catalogMoreBtn).attr('data-more'),
			offset = $(this).offset().top;

		if(catalogMore<40) {
			display = Number(parseUrlQuery().display);
			urlCount = Number($(catalogAllCount).html());
		} else if (catalogMore>40 && parseUrlQuery().display) {
			display = Number(parseUrlQuery().display) ;
			urlCount = Number(parseUrlQuery().display) + 40;
		} else {
			display = 40;
			urlCount = 80;
		}
		$('.js-loader').show();
		$.ajax({
			url: '/ajax-catalog.php',
			type: 'post',
			data: {
				offset: display,
				display: 40,
				parent: catalogParent
			},
			success: function (data) { // при успехе
				let parseData = JSON.parse(data);
				if (parseData.success == 'Y') {

					$(catalog).append(parseData.res);
					$('html, body').scrollTop(offset);
					$(catalogMoreBtn).attr('data-more', parseData.count);
					$('.js-loader').hide();
				} else {
					alert('Что-то пошло не так. Пожалуйста, попробуйте позже.')
				}

				if ("history" in window && "replaceState" in history) {
					history.replaceState(null, null, window.location.pathname + '?display=' + urlCount);
				}

				$(catalogCount).html(urlCount);

				if ((Number(parseUrlQuery().display)) >= Number($(catalogAllCount).html())) {
					$(catalogMoreBtn).hide()
				} else {
					$(catalogMoreBtn).show();

				}

			},
			error: function (data) { // при неудаче
				alert('Что-то пошло не так. Пожалуйста, попробуйте позже.')
			}
		})
	});
}
